.barWrapper {
  width: 300px;
  padding: 0px;
  border-radius: 3px;
  background: white;
  border: 1px solid #dbdbdb;
  margin-bottom: 5px;
  margin-top: 5px;
}

.barAmount {
  text-align: center;
  padding: 5px 0;
  color: white;
  background: #679e5a;
}

.barAmountGreen {
  background: #679e5a;
}

.barAmountYellow {
  background: #d4e259;
}

.barAmountOrange {
  background: #e0ab21;
}

.barAmountRed {
  background: #d95656;
}

.mixture {
  padding-left: 5px;
}
.mixtureName {
  font-weight: bold;
}
.mixtureDescription {
  color: gray;
  padding-left: 28px;
}
.mixtureTotal {
  color: black;
  font-weight: normal;
  width: 25px;
  display: inline-block;
}

.rowItemOptions {
  //   display: none;
  flex-wrap: nowrap;
  opacity: 0;
}
.rowItem:hover .rowItemOptions {
  opacity: 1;
  //   display: block;
}
.rowItem {
  padding: 2px;
  border-radius: 5px;
  display: flex;
  justify-content: space-between;
  flex-wrap: nowrap;
  &:hover {
    background-color: rgb(230, 252, 245);
    opacity: 1;
  }
}
