@import 'src/assets/styles/colors';

.CheckboxFields {
  margin-bottom: 26px;
  width: 100%;
}

.CheckboxFieldsInput {
  display: flex;
  margin-right: 30px;
  margin-bottom: 15px;
}

.CheckboxFieldsInput input:checked + label:after {
  transform: scale(1);
}

.CheckboxFieldsInputSmall input + label:after {
  left: 4px;
  top: 4px;
  width: 8px;
  height: 8px;
}

.CheckboxFieldsInput input + label:after {
  content: '';
  position: absolute;
  display: block;
  border-radius: 1px;
  left: 5px;
  top: 5px;
  background: $color-primary-dark;
  width: 8px;
  height: 8px;
  transition: all 0.15s;
  transform: scale(0);
}

.CheckboxFieldsInput input:checked + label:before {
  border: 2px solid $color-primary-dark;
}

.CheckboxFieldsInputLabel {
  position: relative;
  cursor: pointer;
  padding-left: 32px;

  &:before {
    display: block;
    position: absolute;
    left: 0;
    border-radius: 4px;
    border: 2px solid #7783a1;
    width: 18px;
    height: 18px;
    content: '';
    transition: all 0.15s;
  }
}

.CheckboxFieldsInputLabelSmall {
  font-size: 12px;
  padding-left: 20px;

  &:before {
    width: 16px;
    height: 16px;
  }
}

.CheckboxFieldsInputLabelDisabled {
  cursor: not-allowed;
}

.CheckboxFieldsInputField {
  display: none;
}

.CheckboxFieldsInputError {
  border: 1px solid $color-red !important;
}

.CheckboxFieldsInputWrapper {
  position: relative;
  display: flex;
  height: 45px;
  justify-content: flex-start;
  align-items: center;
}

.CheckboxFieldsInputWrapperError {
  border-bottom: 1px solid $color-red !important;
}

.CheckboxFieldsLabel {
  font-size: 11px;
  color: #a9b5c7;
  text-transform: uppercase;
}

.CheckboxFieldsLabelDisabled {
  cursor: not-allowed;
}

.CheckboxFieldsLabelLeft {
  margin-right: 21px;
  color: #7783a1;
  font-weight: 600;
  text-align: right;
}

.CheckboxFieldsLabelLeft2 {
  display: flex;
  align-items: center;
}

.CheckboxFieldsNoMargin {
  margin-bottom: 0;
}
