@media print {
  .AppContent {
    padding: 0 !important;
    position: absolute;
    top: 0;
    width: 8.5in;
    height: 11in;
  }

  .App__content {
    padding: 0 !important;
  }
  .mantine-SimpleGrid-root {
    display: block !important;
    width: 8.5in;
  }
  .mantine-Card-cardSection {
    width: 100%;
    display:flex;
    justify-content: center;
    align-items: center;
  }
  .mantine-Card-root {
    width: 100%;
  }
  .no-print {
    display: none;
  }
  .chartjs-size-monitor {
    width:700px;
  }
  body {
    -webkit-print-color-adjust: exact !important;
  }
}
