.content {
  padding-top: 0px;
  padding-bottom: 0px;
  padding-left: 0px;
}

.navigationTitle {
  margin: 12px 16px;
  padding-bottom: 16px;
  border-bottom: 1px solid #f1f4f8;
}

.navigationButton {
  border: none;
  display: flex;
  flex-direction: row;
  margin-bottom: 8px;
  color: black;
  min-height: 50px;

}

.navigationButtonActive {
  background-color: rgba(230, 252, 245, 0.7) !important;
}