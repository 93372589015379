.categoryRow {
  font-size: 18px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  border-bottom: 1px solid #cccccc;
  padding: 10px 0;
  cursor: pointer;
  &:hover {
    background-color: #15b88614;
  }
}

.categoryRowActive {
  background-color: #15b88626;
  &:hover {
    background-color: #15b88626;
  }
}
.categoryRowIconActive {
  color: #15b886;
}

.categoryRowName {
  margin-left: 5px;
}

.categoryRowInfo {
  margin-left: auto;
  margin-right: 15px;
}

.categoryRowInfoIcon {
  color: gray;
  margin-left: 10px;
}
