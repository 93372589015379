.field {
}
.noteField {
  width: 100%;
}
.buttons {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  button {
    margin-left: 10px;
  }
}
