.list {
  display: flex;
}
.item {
  padding: 10px 20px;
  text-decoration: none;
  color: grey;
  border-radius: 5px;
  margin-right: 10px;
  margin-bottom: 10px;
  border: 1px solid #c3c3c3;
  transition: 0.12s all;
  &:hover {
    background: #f7f7f7;
    cursor: pointer;
  }
}

.filterLabelIcon {
  font-size: 10px;
  display: inline-block;
  margin-right: 4px;
}

.filterLabel {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  transition: all 0.12s;
  cursor: pointer;

  &:hover {
    color: #42c19a;
  }
  & i {
    transition: all 0.12s;
    color: #d2d2d2;
  }
  &:hover i {
    color: #42c19a;
  }
}

.filterLabelActive {
  color: #42c19a;
  i {
    color: #42c19a;
  }
}
