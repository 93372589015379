.marker {
  width: 30px;
  margin-top: -15px;
  margin-left: -15px;
  height: 30px;
  background: #12b886;
  border-radius: 50%;
  opacity: 1;
  cursor: pointer;
  transition: all 0.12s;
  &:hover {
    opacity: 1;
  }
}

.root {
  position: absolute;
}
