@import 'src/assets/styles/colors';
.SelectField {
  position: relative;
  cursor: pointer;
  .TextField,
  input {
    cursor: pointer;

    &__input--disabled {
      background: none;
    }
  }
  &--disabled {
    cursor: not-allowed;
    input {
      cursor: not-allowed;
    }
    .FieldWrapper__input-wrapper {
      background: rgba(100, 100, 100, 0.07);
    }
  }

  &__options {
    box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.15);
    width: 100%;
    visibility: hidden;
    opacity: 0;
    overflow: hidden;
    position: absolute;
    transition: all 0.25s;
    top: 70px;
    // top: 100%;
    // margin-top: 20px;
    right: -4px;
    border-radius: 4px;
    background-color: #fff;
    z-index: 10;
    max-height: 200px;
    overflow-y: scroll;
    &--active {
      opacity: 1;
      visibility: visible;
    }
    &:before {
      content: '';
      clip-path: inset(-5px -5px 0px 0px);
      transform: rotate(-45deg);
      right: 25px;
      top: -6px;
      background: white;
      z-index: 10;
      position: absolute;
      width: 12px;
      height: 12px;
      box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.15);
    }
    &__option {
      border: none;
      width: 100%;
      text-align: left;
      padding: 20px 20px;
      background-color: transparent;
      font-size: 14px;
      color: #3d3d3d;
      transition: all 0.2s;
      outline: none;
      &--active,
      &:hover {
        cursor: pointer;
        color: $color-primary-dark;
      }
    }
  }
}
