@import 'src/assets/styles/colors';

.ConfirmModal {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1050;
  display: none;
  outline: 0;
  display: block;
  background: rgba(0, 0, 0, 0.35);

  &__wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
  }
  &__content {
    border: 1px solid #f2f6f7;
    border-radius: 3px;
    background-color: #fff;
    height: auto;
    padding: 20px;
  }
  &__title {
    font-size: 16px;
    line-height: 25.88px;
    font-weight: 300;
  }
  &__paragraph {
    color: #8b91a0;
    font-weight: 400;
    letter-spacing: 0.01em;
    font-size: 13px;
    line-height: 20px;
    margin-top: 5px;
  }
  &__buttons {
    align-items: center;
    justify-content: center;
    display: flex;
    margin: 10px 0 0;
    button {
      margin-left: 10px;
    }
  }
  &__close {
    border: none;
    background: none;
    position: absolute;
    right: 10px;
    top: 10px;
    font-size: 20px;
    transition: all 0.3s;
    cursor: pointer;
    outline: none;
    &:hover {
      color: $color-red;
    }
  }
}

.confirm-modal-anim-enter {
  opacity: 0;
  transition: all 0.2s;
  .ConfirmModal__wrapper {
    transform: translateY(100%);
    transition: all 0.4s;
  }
}

.confirm-modal-anim-enter-active {
  opacity: 1;
  .ConfirmModal__wrapper {
    transform: translateY(0);
    transition: all 0.4s;
  }
}

.confirm-modal-anim-exit {
  opacity: 1;
  transition: all 0.2s;
  .ConfirmModal__wrapper {
    transform: translateY(0%);
    transition: all 0.6s;
  }
}

.confirm-modal-anim-exit-active {
  opacity: 0;
  transition-delay: 0.2s;
  .ConfirmModal__wrapper {
    transform: translateY(100%);
    transition: all 0.6s;
  }
}
