.logo {
  width: 40px;
  margin: 0 15px;
}
.logoPlaceholder {
  margin: 0 15px;
  width: 40px;
  height: 40px;
  background: #f6f6f6;
  border: 1px solid #dfdfdf;
}
