.ContentLoader {
  width: 100%;
  min-height: 100vh;
  &__content {
    display: none;
    &--active {
      display: block;
    }
  }
  &__loader {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    width: 100%;
  }
}
