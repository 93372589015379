.filters {
  display: flex;
  & > * {
    margin-right: 15px;
  }
}

.deleteAlertButton {
  background: none;
  padding: 0 5px;
  border-radius: 5px;
  margin: auto 0;
  color: white;
  border: 1px solid white;
  height: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  &:hover {
    background: white;
    color: #7f7f7f;
  }
}
.deleteDeviceButton {
  border: 1px solid white;
  border-radius: 50%;
  display: flex;
  color: white;
  justify-content: center;
  width: 25px;
  height: 25px;
  align-items: center;
  background: none;
  margin-left: auto;
  cursor: pointer;

  &:hover {
    background: white;
    color: #7f7f7f;
  }
}
.deviceBoxActions {
  display: none;
  width: 100%;
  height: 40px;
  align-items: center;
  padding: 0 10px;
  left: 0px;
  top: 0px;
  position: absolute;
  background-color: rgba(0, 0, 0, 0.7);
}
.deviceBox:hover .deviceBoxActions {
  display: flex;
}

.iconQrCode {
  color: #00ab91;
  position: absolute;
  top: 5px;
  left: 5px;
  font-size: 18px;
}
