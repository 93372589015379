@media print {
  .ListHeading h1 {
    display: none;
  }
  .App__sidebar {
    display: none;
  }
  .TextField__input {
    font-weight: 700;
    padding: 0 0px;
    font-size: 14px;
    width: auto;
    color: black;
  }
  .FieldWrapper__icon {
    display: none;
  }
  .FieldWrapper__input-wrapper {
    border: none;
    font-weight: 700;
  }
}

@page {
  size: auto;
  margin: 5mm;
}
