.container {
//   max-width: 100%;
//   margin: 0 auto;
//   display: flex;
//   justify-content: flex-start;
//   align-items: flex-start;
//   flex-wrap: wrap;
//   flex-direction: row;
// display: grid;
// grid-template-columns: repeat(4, 1fr);
// grid-auto-flow: dense;
// gap: 10px;
}

.item {
//   background-color: orange;
//   height: auto;
//   width: 31%;
margin: 0;
display: grid;
grid-template-rows: 1fr auto;
//   margin: 1%;
//   padding: 10px;
}

.item:nth-child(2) {
  background-color: pink;
  height: 250px;
}

.item:nth-child(3) {
  height: 190px;
}

.item:nth-child(4) {
  background-color: aqua;
  height: 220px;
}
