@import 'src/assets/styles/colors';
.TextField {
  &__input {
    border: none;
    padding: 15px 15px;
    font-size: 14px;
    width: 100%;
    outline: none;
    color: #7783a1;
    border-radius: inherit;
    &--disabled {
      background: rgba(100, 100, 100, 0.07);
      cursor: not-allowed;
    }
    &--error {
      border: 1px solid $color-red !important;
    }
    &::placeholder {
      color: #9c9c9c;
    }
    &--icon {
      padding-right: 0;
    }
  }
}
