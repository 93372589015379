.legend {
  display: flex;
  padding: 20px 0;
}
.legendIcon {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 25px;
}
.legendIconText {
  text-align: center;
  margin-left: 10px;
}

.button {
  margin: 0 !important;
}
.uploadButton {
  margin-top: 30px;
}

.mapOption {
  width: 100%;
  padding: 10px 5px;
  border-bottom: 1px solid rgb(233, 233, 233);
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  &:hover {
    background-color: rgba(245, 245, 245);
  }
}
.mapOptionActive {
  background-color: rgb(237, 237, 237);
}
.mapOptionAdd {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.device {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 5px;
  border-bottom: 1px solid rgb(233, 233, 233);
}
.deviceDesc {
  font-size: 12px;
}
.delete {
  border: none;
  margin: none;
  background: none;
  padding: none;
  cursor: pointer;
  color: rgb(226, 226, 226);
  outline: none;
  i:hover {
    color: red;
  }
}

.deviceType {
  display: flex;
  margin-right: 15px;
  margin-bottom: 10px;
  align-items: center;
  cursor: pointer;
  &:hover {
    font-weight: bold;
  }
}
.deviceTypeSelected {
  font-weight: bold;
}
.deviceTypeColor {
  width: 25px;
  height: 25px;
  margin-right: 10px;
}
.deviceTypeName {
}

.iconWrapper {
  width: 40px;
  height: 40px;
  padding: 0;
  margin: 0;
  position: relative;
}
.icon {
  content: '';
  border-radius: 50%;
  height: 40px;
  width: 40px;
  border-radius: 50%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  font-size: 15px;
  &:after {
    position: absolute;
    top: 0%;
    left: 0%;
    transform: translate(-50%, -50%);
    height: 40px;
    width: 40px;
    content: '';
    border-radius: 50%;
    transform: scale(1);
  }
}
.iconNormal {
  background: #77a1d8;
  &:after {
    box-shadow: 0 0 0 0 #77a1d8;
    animation: none;
  }
}
.iconRed {
  background: rgba(255, 82, 82, 1);
  &:after {
    box-shadow: 0 0 0 0 rgba(255, 82, 82, 1);
    // animation: pulse-red 1s infinite;
  }
}
.iconYellow {
  background: rgb(255, 220, 82);
  &:after {
    box-shadow: 0 0 0 0 rgb(255, 220, 82);
    // animation: pulse-yellow 1s infinite;
  }
}
.iconYellowRed {
  background: rgb(255, 220, 82);
  &:before {
    content: '';
    position: absolute;
    width: 25px;
    height: 25px;
    background: rgba(255, 82, 82, 1);
    box-shadow: 0 0 0 0 rgba(255, 82, 82, 1);
    border-radius: 50%;
    z-index: -1;
  }
  &:after {
    box-shadow: 0 0 0 0 rgb(255, 220, 82);
    // animation: pulse-red 1s infinite;
  }
}
.iconGreen {
  background: #61c815;

  &:after {
    box-shadow: 0 0 0 0 #61c815;
    // animation: pulse-green 1s infinite;
  }
}

@keyframes pulse-green {
  0% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 #61c815;
  }

  70% {
    transform: scale(1);
    box-shadow: 0 0 0 10px rgba(255, 82, 82, 0);
  }

  100% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(255, 82, 82, 0);
  }
}
@keyframes pulse-yellow {
  0% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgb(255, 220, 82);
  }

  70% {
    transform: scale(1);
    box-shadow: 0 0 0 10px rgba(255, 82, 82, 0);
  }

  100% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(255, 82, 82, 0);
  }
}

@keyframes pulse-red {
  0% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(255, 82, 82, 0.7);
  }

  70% {
    transform: scale(1);
    box-shadow: 0 0 0 10px rgba(255, 82, 82, 0);
  }

  100% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(255, 82, 82, 0);
  }
}

.leftScroll {
  flex: 1;
  overflow: scroll;
  margin-bottom: 0;
  max-height: 70vh;
}
.leftWrapper {
  max-height: 80vh;
  display: flex;
  min-height: 100%;
  flex-direction: column;
}

.tabsList {
  flex-wrap: nowrap;

  & button {
    padding-left: 8px;
    padding-right: 8px;
  }
}