.root {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
  padding: 0 15px;
}

.button {
  justify-content: center;
  align-items: center;
  display: flex;
  opacity: 0.5;
  cursor: pointer;
  color: white;
  width: 30px;
  height: 20px;
  margin-right: 10px;
}
.buttonActive {
  opacity: 1;
}
