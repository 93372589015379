@import 'src/assets/styles/colors';

.ForgotPassword {
  background: black;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  &__error {
    background: $color-red;
    color: white;
    display: block;
    padding: 7px 10px;
    border-radius: 5px;
    margin: 10px 0;
    font-size: 13px;
  }
  &__wrapper {
    width: 260px;
  }
  &__language {
    margin-top: 30px;
  }
  &__header {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-bottom: 60px;
    &__logo {
      height: 150px;
    }
  }
  &__submit {
    width: 100%;
    margin: 70px 0 0;
    text-align: center;
    justify-content: center;
  }
  &__input {
    svg,
    svg * {
      fill: #707070 !important;
    }
    input {
      color: #707070 !important;
      background: #424242;
      border: none;
    }
    label {
      color: white;
    }
    margin-bottom: 16px;
  }
  &__link {
    text-decoration: none;
  }
  &__notification {
    position: absolute;
    bottom: 50px;
    right: 20px;
  }
}
