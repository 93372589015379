@import 'src/assets/styles/colors';
.DateField {
  position: relative;
  cursor: pointer;
}

.DateFieldCalendar {
  position: absolute;
  top: 100%;
  z-index: 20;
  background: white;
  border: 1px solid #ebebeb;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.2);
  border-radius: 4px;
}
