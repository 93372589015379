.Fullscreen {
  &.fullscreen-enabled {
    background-color: white;
    padding: 50px;
    overflow: auto;

    & > .Card {
      overflow: auto;
      resize: both;
    }
  }

  &:not(.fullscreen-enabled) > .Card {
    width: auto !important;
    height: auto !important;
  }

  &__title {
    &--end {
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
    }
  }
}
