.container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  max-width: 100%;
  margin-bottom: 20px;
  max-height: 110px;
  overflow-y: scroll;

  &::-webkit-scrollbar {
    display: none;
  }
  scrollbar-width: none;

  background:
      /* Shadow Cover TOP */
      linear-gradient(
        white 30%,
        rgba(255, 255, 255, 0)
      ) center top,
      
      /* Shadow Cover BOTTOM */
      linear-gradient(
        rgba(255, 255, 255, 0), 
        white 70%
      ) center bottom,
      
      /* Shadow TOP */
      radial-gradient(
        farthest-side at 50% 0,
        rgba(0, 0, 0, 0.2),
        rgba(0, 0, 0, 0)
      ) center top,
      
      /* Shadow BOTTOM */
      radial-gradient(
        farthest-side at 50% 100%,
        rgba(0, 0, 0, 0.2),
        rgba(0, 0, 0, 0)
      ) center bottom;
    
    background-repeat: no-repeat;
    background-size: 100% 40px, 100% 40px, 100% 14px, 100% 14px;
    background-attachment: local, local, scroll, scroll;
}

.list {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  max-width: 100%;
  gap: 4px;
}

.listItem {
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
  margin-left: 10px;
}

.boxSpan {
  display: inline-block;
  height: 12px;
  width: 24px;
  border-radius: 2px;
  margin-right: 6px;
}
