@import 'src/assets/styles/colors';

.notifications {
  margin-right: 20px;
}
.notificationsContent {
  width: 400px;
}

.notification {
  color: black;
  display: flex;
  align-items: center;
  padding: 10px 0px;
  border: none;
  background: none;
  margin: 0;
  text-align: left;
  border-bottom: 1px solid #e8e8e8;
  width: 100%;
  &:hover {
    background-color: rgba(0, 0, 0, 0.05);
  }
}
.notificationIcon {
  margin-right: 10px;
  font-size: 20px;
}

.notificationTime {
  margin-left: auto;
}
.notificationTitle {
  font-size: 14px;
  color: black;
}
.notificationContent {
}
.notificationDescription {
  font-size: 12px;
  color: gray;
}
.notificationBody {
}

.showAll {
  margin-top: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}
