@import 'src/assets/styles/colors';

$navigationWidth: 200px;
.App {
  min-height: 100vh;
  display: flex;
  flex-direction: row;
  &__sidebar {
    min-height: 100vh;
    width: $navigationWidth;
    background: #242424;
    // background: #3b3b3b;
    z-index: 10;
    position: fixed;
  }
  &__content {
    background-color: white;
    min-height: 100vh;
    padding-left: $navigationWidth;
    width: 100%;
  }
}
