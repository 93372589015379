@import 'src/assets/styles/colors';
.HeaderTextField {
  width: 100%;
  &__input {
    border: none;
    padding: 15px 15px;
    font-size: 14px;
    width: 100%;
    font-family: Open Sans;
    font-size: 34px;
    color: #35404a;
    font-weight: 700;
    border: none;
    border-bottom: 1px solid #d5e1eb;
    outline: none;
    &--disabled {
      background: rgba(100, 100, 100, 0.07);
      cursor: not-allowed;
    }
    &--error {
      border: 1px solid $color-red !important;
    }
    &::placeholder {
      color: #9c9c9c;
    }
    &--icon {
      padding-right: 0;
    }
  }
}
.CampaignHeader {
  padding: 31px 36px;
  display: flex;
  background: white;
  position: sticky;
  top: 0;
  z-index: 15;
  align-items: center;
  &__right {
    margin-left: auto;
  }

  border-bottom: 1px solid #d5e1eb;
}
.CampaignMap {
  height: calc(100vh);
  &__wrapper {
    position: -webkit-sticky;
    position: sticky;
    top: 0px;
    height: 400px;
  }
}
.CampaignForm {
  padding: 53px 0px;
  &__separator {
    background: #f1f4f8;
    height: 1px;
    width: auto;
    margin: 50px -50px;
  }
  &__content-type {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .CheckboxField {
      margin-bottom: 10px;
    }
    &__error {
      .FieldWrapper__input-wrapper {
        border: none;
      }
    }
    .DateField {
      width: 100%;
    }
    &__separator {
      margin: 0 20px 26px;
    }
  }
}
.CWrapper {
  padding: 60px;
}
