@import 'src/assets/styles/colors';

.root {
  height: 80px;
  width: 100%;
  background: #242424;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 20px;
  position: sticky;
  top: 0;

  z-index: 200;
}
.right {
  margin-left: auto;
  display: flex;
  flex-direction: row;
  align-items: center;
  color: white;
}

.avatar {
  width: 50px;
  height: 50px;
  border-radius: 50%;
}

.user {
  display: flex;
  flex-direction: row;
  align-items: center;
  background: none;
  border: none;
  cursor: pointer;
  color: white;
  &:hover {
    opacity: 0.8;
  }
}

.name {
  margin-right: 20px;
  text-align: left;
}

.noNotifications {
  display: flex;
  justify-content: center;
  align-items: center;
  color: black;
  height: 100px;
}

.notifications {
  margin-right: 20px;
}
.notificationsContent {
  width: 400px;
}

.notification {
  color: black;
  display: flex;
  align-items: center;
  padding: 10px 0px;
  border-bottom: 1px solid #e8e8e8;
}
.notificationIcon {
  margin-right: 10px;
  font-size: 20px;
}

.notificationTime {
  margin-left: auto;
}
.notificationTitle {
  font-size: 14px;
  color: black;
}
.notificationContent {
}
.notificationDescription {
  font-size: 12px;
  color: gray;
}
.notificationBody {
}

.showAll {
  margin-top: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.userType {
  color: gray;
}
