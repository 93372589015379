@import 'src/assets/styles/colors';

.Navigation {
  height: 100vh;
  display: flex;
  flex-direction: column;
  position: sticky;
  top: 0;
  // overflow-y: scroll; //show only when a lot of items
  &__inner {
    display: flex;
    // overflow-y: scroll; //show only when a lot of items
    flex-direction: column;
    flex: 1;
  }
  &__logo {
    padding: 15px;
    max-height: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__counter {
    color: white;
    background: #c62b2b;
    font-size: 13px;
    width: 25px;
    height: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
  }
}
