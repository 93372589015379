.envBanner {
  position: fixed;
  z-index: 999;
  height: 50px;
  display: flex;
  flex-direction: row;
  width: 200px;
  top: 0;
  background-color: rgba(230, 252, 245, 0.75);
}
