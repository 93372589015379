@import 'src/assets/styles/colors';
.FieldWrapper {
  margin-bottom: 26px;
  width: 100%;
  &__icon-left {
    padding: 0 13px;
    display: flex;
    border-right: 1px solid #f1f4f8;
    align-items: center;
    align-self: stretch;
    svg {
      width: 21px;
      height: 21px;
    }
  }
  &--label-left {
    display: flex;
    align-items: center;
  }
  &__loader {
    transform: scale(0.7);
    position: absolute;
    right: 10px;
  }
  &--no-margin {
    margin-bottom: 0;
  }
  &__tooltip {
    margin-left: 10px;
    position: relative;
    svg {
      cursor: pointer;
    }
    &:hover svg * {
      fill: $color-primary-dark !important;
    }
    &:hover &__content {
      opacity: 1;
      visibility: visible;
    }
    &__content {
      &:before {
        content: '';
        clip-path: inset(-5px -5px 0px 0px);
        transform: rotate(-135deg);
        left: -6px;
        top: 22px;
        background: white;
        z-index: 10;
        position: absolute;
        width: 12px;
        height: 12px;
        box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.15);
      }
      position: absolute;
      top: -20px;
      left: 100%;
      margin-left: 15px;
      padding: 20px 25px;
      box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.15);
      visibility: hidden;
      opacity: 0;

      transition: all 0.25s;
      border-radius: 4px;
      background-color: #fff;
      z-index: 10;
    }
  }
  &__label {
    font-size: 11px;
    color: #a9b5c7;
    text-transform: uppercase;
    margin-bottom: 11px;
    height: 18px;

    display: flex;
    align-items: center;
    &--left {
      margin-right: 21px;
      color: #7783a1;
      font-weight: 600;
      text-align: right;
      margin-bottom: 0;
    }
  }
  &__input-wrapper {
    position: relative;
    display: flex;
    align-items: center;
    border: 1px solid #dae1ed;
    border-radius: 4px;
    background-color: #ffffff;
    input {
      border-radius: 4px;
      background: transparent;
    }
    &--disabled {
      background: rgba(100, 100, 100, 0.07);
    }
    &--error {
      border: 1px solid $color-red !important;
    }
    &--no-border {
      border: none;
    }
  }
  &__icon {
    width: 25px;
    height: 25px;
    margin-right: 12px;
    margin-left: 12px;
  }
}
