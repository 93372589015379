.Table {
  width: 100%;
  border-collapse: collapse;
  border-spacing: 0;
  &--fixed {
    table-layout: fixed;
  }
  min-height: 100px;
}
.TableWrapper {
  position: relative;
  &__loader {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 100;
    opacity: 0;
    background-color: rgba(0, 0, 0, 0.6);
    transition: all 0.3s;
    display: flex;
    justify-content: center;
    align-items: center;
    visibility: hidden;
    &--active {
      visibility: visible;
      opacity: 1;
    }
  }
}
.Td {
  text-align: left;
  font-size: 13px;
  padding: 10px 15px;
  color: #35404a;
  &--no-break {
    white-space: nowrap;
  }
}
.Tr .Td:first-child,
.Tr .Th:first-child {
  padding-left: 7px;
}
.Tr .Td:last-child,
.Tr .Th:last-child {
  padding-right: 7px;
}

.Thead .Th {
  border-top: 1px solid #f1f4f8;
  padding: 13px 15px;
  font-size: 10px;
  border-bottom: 1px solid #f1f4f8;
}
.Tbody .Tr .Td {
  border-bottom: 1px solid #d3d3d3;
  // border-bottom: 1px solid #f1f4f8;
  transition: all 0.3s;
  word-break: break-word;
}

.Tbody .Tr:hover .Td {
  background: rgba(#f1f4f8, 0.5);
}

.Th {
  text-align: left;
  text-transform: uppercase;
  padding: 0 5px;
  font-size: 12px;
  color: #7783a1;
  font-weight: 600;
  &--short {
    width: 50px;
  }
  &__sort {
    display: inline-block;
    vertical-align: middle;
    border: none;
    background: none;
    outline: none;
    cursor: pointer;
    padding: 0;
    margin-left: 10px;
  }
}
