@import 'react-big-calendar/lib/sass/styles';
@import 'react-big-calendar/lib/addons/dragAndDrop/styles';
@import 'src/assets/styles/colors';

.rbc-calendar {
  min-height: 100%;
  font-family: 'Open Sans', sans-serif;
}

.rbc-event {
  background-color: $color-primary-dark;
}

.rbc-today {
  background-color: rgba(66, 193, 154, 0.2);
}

.rbc-off-range-bg {
  background-color: #E9ECEF;
}

.rbc-btn-group {
  button {
    &.rbc-active {
      background-color: $color-primary-dark;
      color: white;

      &:focus {
        background-color: $color-primary-dark;
        color: white;
      }

      &:hover {
        background-color: rgba(66, 193, 154, 0.3);
      }
    }

    &:focus {
      background-color: $color-primary-dark;
      color: white;
    }
    
    &:hover {
      background-color: rgba(66, 193, 154, 0.8);
    }
  }
}