@import 'src/assets/styles/colors';
.FieldLabel {
  font-size: 11px;
  color: #a9b5c7;
  text-transform: uppercase;
  margin-bottom: 11px;
  height: 18px;

  display: flex;
  align-items: center;
  &--left {
    margin-right: 21px;
    color: #7783a1;
    font-weight: 600;
    text-align: right;
    margin-bottom: 0;
  }
  &__tooltip {
    margin-left: 10px;
    position: relative;
    svg {
      cursor: pointer;
    }
    &:hover svg * {
      fill: $color-primary-dark !important;
    }
    &:hover &__content {
      opacity: 1;
      visibility: visible;
    }
    &__content {
      &:before {
        content: '';
        clip-path: inset(-5px -5px 0px 0px);
        transform: rotate(-135deg);
        left: -6px;
        top: 22px;
        background: white;
        z-index: 10;
        position: absolute;
        width: 12px;
        height: 12px;
        box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.15);
      }
      position: absolute;
      top: -20px;
      left: 100%;
      margin-left: 15px;
      padding: 15px 15px;
      box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.15);
      visibility: hidden;
      opacity: 0;
      font-size: 13px;
      text-transform: none;
      min-width: 200px;

      transition: all 0.25s;
      border-radius: 4px;
      background-color: #fff;
      z-index: 10;
    }
  }
}
