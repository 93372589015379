@import 'src/assets/styles/colors';

.Modal {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1050;
  outline: 0;
  background: rgba(0, 0, 0, 0.35);

  &__wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
  }
  &__content {
    border: 1px solid #f2f6f7;
    border-radius: 3px;
    background-color: #fff;
    height: auto;
    padding: 30px;
    position: relative;
    min-width: 40%;
    max-width: 60%;
  }
  &__sub-title {
    font-size: 12px;
    color: #7783a1;
    margin-bottom: 15px;
    display: flex;
    align-items: center;
    svg {
      margin-right: 2px;
    }
  }
  &__title {
    font-size: 23px;
    color: #35404a;
    font-weight: 700;
    text-align: center;
    margin-bottom: 46px;
    &--left {
      text-align: left;
    }
    &--with-sub {
      margin-bottom: 6px;
    }
  }

  &__close {
    border: none;
    background: none;
    position: absolute;
    right: 10px;
    top: 10px;
    font-size: 20px;
    transition: all 0.3s;
    cursor: pointer;
    outline: none;
    &:hover {
      color: $color-red;
    }
  }
}

.modal-anim-enter {
  opacity: 0.01;
}
.modal-anim-enter-active {
  opacity: 1;
  transition: opacity 200ms ease-in;
}
.modal-anim-exit {
  opacity: 1;
}
.modal-anim-exit-active {
  opacity: 0.01;
  transition: opacity 200ms ease-in;
}
