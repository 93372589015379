@import '~react-day-picker/lib/style.css';
html,
body {
  font-family: 'Open Sans', sans-serif;
  font-size: 14px;
  font-weight: 400;
  // background: #171717;
  background: white;
  color: #626262;
  width: 100%;
  height: 100%;
}

* {
  box-sizing: border-box;
}
img {
  max-width: 100%;
}
#root {
  height: 100%;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-transition-delay: 99999s;
}
