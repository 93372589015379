.icon-alert-date {
  color: #f50056 !important;
  opacity: 0.7;
  font-size: 12px;
  vertical-align: middle;
}

.device-box.device-box--no-border {
  box-shadow: none;
  border: none;
  padding: 0;
  margin: 0 auto;
}
.device-box {
  width: 140px;
  margin: 15px;
  border: 1px solid #efefef;
  border-radius: 3px;
  box-shadow: 3px 7px 16px -4px rgba(0, 0, 0, 0.15);
  padding: 15px 15px 0;
  transition: all 0.12s;
  cursor: pointer;
  font-size: 12px;
  position: relative;
  &:hover {
    box-shadow: 3px 16px 16px -4px rgba(0, 0, 0, 0.25);
  }
  &__header {
    display: flex;
    flex-direction: row;
    margin-bottom: 10px;
  }
  &__row {
    margin-bottom: 8px;
  }
  &__desc {
    font-size: 12px;
    color: grey;
    margin-bottom: 10px;
    padding-bottom: 10px;
    border-bottom: 1px solid #d4d4d4;
  }
  &__id {
    text-align: center;
    font-size: 12px;
    border-radius: 50%;
    border: 1px solid #b5b5b5;
    width: 40px;
    height: 40px;
    margin: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    margin-left: auto;
    &__icon {
      z-index: 0;
      font-size: 40px;
      opacity: 0.4;
      &--alert {
        color: red;
      }
      &--good {
        color: green;
      }
    }
  }

  &__icon {
    width: 15px;
    height: 15px;
    vertical-align: center;
    margin-right: 10px;
    display: inline-block;
    text-align: center;
    color: #d4d4d4;
  }
  &__battery {
  }
  &__alert {
  }
  &__status {
    margin: 10px 0;
    text-align: center;
  }
  &__last-action {
  }
  &__place {
  }
}

.device-boxes-wrapper {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin: -15px -15px;
}
