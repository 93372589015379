.ListHeading {
  &__wrapper {
    display: flex;
    align-items: center;
    border-bottom: 1px solid #f1f4f8;
    padding: 30px 60px;
  }
  &__content {
    padding: 0 30px;
  }
  &__footer {
    padding: 20px 60px;
  }
  h1 {
    font-size: 34px;
    color: #35404a;
    margin: 0;
    padding: 0;
    font-weight: 700;
  }
}
