.root {
  display: flex;
  height: 100%;
}
.nav {
  width: 200px;
  padding-top: 60px;
  height: 100%;
  // background-color: #242424;
  background-color: #3b3b3b;
}
.navItem {
  width: 100%;
  display: block;
  padding: 10px 15px;
  border-bottom: 1px solid #585858;
  color: #7b7b7b;
  &:hover {
    color: #1ad49c;
  }
  // border-bottom: 1px solid #dadada;
  // color: #484848;
  text-decoration: none;
}
.content {
  width: 100%;
  padding-left: 30px;
}

.activeNavItem {
  color: #12b886;
}
