@import 'src/assets/styles/colors';

.RotateLoader {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 30px;
  height: 30px;
  margin-left: -15px;
  margin-top: -15px;
  border: 2px solid $color-primary-dark;
  border-bottom-color: transparent;
  animation-iteration-count: 3;
  animation-fill-mode: both;
  display: inline-block;
  transition: all 0.3s;
  vertical-align: middle;
  background-color: #fff;
  border-radius: 100%;
  background: transparent !important;
  animation: rotate-loader 1.25s 0s linear infinite;
  &--relative {
    position: relative;
    top: initial;
    left: initial;
    margin: 0 !important;
  }
}

@keyframes rotate-loader {
  0% {
    transform: rotate(0deg);
  }
  50% {
    transform: rotate(180deg);
  }
  100% {
    transform: rotate(360deg) scale(1);
  }
}
