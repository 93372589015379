.list {
  display: flex;
}
.item {
  padding: 10px 20px;
  text-decoration: none;
  color: grey;
  border-radius: 5px;
  margin-right: 10px;
  margin-bottom: 10px;
  border: 1px solid #c3c3c3;
  transition: 0.12s all;
  &:hover {
    background: #f7f7f7;
    cursor: pointer;
  }
}
