@import 'src/assets/styles/colors';

.NavigationItem {
  position: relative;
  color: #bebebe;
  text-decoration: none;
  align-items: center;

  &__icon {
    width: 30px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 16px;
  }

  svg {
    fill: white;
    transition: all 0.15s;
    &:hover {
      fill: white;
    }
  }
  &--bottom {
    margin-top: auto;
  }
  &--active {
    background: #3b3b3b;
    // background: #4f4f4f;
  }
  &--active,
  &--active svg {
    fill: white;
    color: white;
    // fill: #17d1af;
    // color: #17d1af;
  }
  &--active &__tooltip,
  &:hover > &__tooltip {
    color: white;
    // color: #17d1af;
  }
  &--sub &__tooltip {
    color: #818181;
  }
  &--sub-active &__tooltip {
    color: #b3b3b3;
  }
  &__tooltip {
    padding: 0 0 0 10px;
    border-radius: 4px;
    transition: all 0.25s;
    font-size: 14px;
    // white-space: nowrap;
  }
}
.NavigationItemLink {
  width: 200px;
  height: 50px;
  color: #bebebe;
  text-decoration: none;
  display: flex;
  align-items: center;
  padding: 0 15px;
  cursor: pointer;
  &--sub {
    height: 30px;
  }
  &__icon {
    width: 30px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 16px;
  }

  svg {
    fill: white;
    transition: all 0.15s;
    &:hover {
      fill: white;
    }
  }
  &:hover {
    color: white;
  }
  &--bottom {
    margin-top: auto;
  }

  &--active {
  }
  &--active-text {
    color: white;
  }
  &--active,
  &--active svg {
    fill: white;
    color: white;
  }
  &--active &__tooltip,
  &:hover &__tooltip {
    color: white;
  }
  &--sub-active {
    background: #616161;
  }
  &--sub-active &__tooltip {
    color:#b3b3b3 ;
  }
  &__tooltip {
    padding: 0 0 0 10px;
    border-radius: 4px;
    transition: all 0.25s;
    font-size: 14px;
    // white-space: nowrap;
  }
}
