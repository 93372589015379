.row {
  margin-bottom: 8px;
}

.label {
  font-weight: 600;
}
.value {
}

.removeFileRow {
  display: flex;
  align-items: center;
  width: 80%;
}
.removeFileRow:hover .removeFileIcon {
  display: inline-block;
}
.removeFileIcon {
  display: none;
  opacity: 0.2;
  margin-left: 20%;
  cursor: pointer;
  margin-left: auto;
  &:hover {
    opacity: 1;
    color: red;
  }
}

.commentEdit {
  font-size: 12px;
  color: gray;
  cursor: pointer;
  margin-right: 5px;
  &:hover {
    color: black;
  }
}
