@import 'src/assets/styles/colors';
.CheckboxField {
  margin-bottom: 25px;
  display: block;
  cursor: pointer;

  &__label {
    font-size: 13px;
    color: #ababab;
    margin-left: 12px;
    display: inline-block;
    vertical-align: middle;
  }
  &__input {
    display: none;
  }
  &__box {
    width: 16px;
    height: 16px;
    border-radius: 2px;
    background-color: #4a4a4a;
    vertical-align: middle;
    transition: all 0.2s;
    color: black;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    color: rgba(0, 0, 0, 0);
    &--active {
      background-color: #51dbb3;
      color: rgba(0, 0, 0, 1);
    }
  }
  &--disabled {
    cursor: not-allowed;
    input {
      cursor: not-allowed;
    }
  }
  &--light &__box {
    background-color: white;
    border: 1px solid #dae1ed;
  }
}
