.iconWrapper {
  width: 40px;
  height: 40px;
  padding: 0;
  margin: 0;
  position: relative;
}
.icon {
  content: '';
  border-radius: 50%;
  height: 20px;
  width: 20px;
  border-radius: 50%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  font-size: 15px;
  &:after {
    position: absolute;
    top: 0%;
    left: 0%;
    transform: translate(-50%, -50%);
    height: 20px;
    width: 20px;
    content: '';
    border-radius: 50%;
    transform: scale(1);
  }
}
.iconNormal {
  background: #77a1d8;
  &:after {
    box-shadow: 0 0 0 0 #77a1d8;
    animation: none;
  }
}
.iconRed {
  background: rgba(255, 82, 82, 1);
  &:after {
    box-shadow: 0 0 0 0 rgba(255, 82, 82, 1);
    // animation: pulse-red 1s infinite;
  }
}
.iconYellow {
  background: rgb(255, 220, 82);
  &:after {
    box-shadow: 0 0 0 0 rgb(255, 220, 82);
    // animation: pulse-yellow 1s infinite;
  }
}
.iconYellowRed {
  background: rgb(255, 220, 82);
  &:before {
    content: '';
    position: absolute;
    width: 25px;
    height: 25px;
    background: rgba(255, 82, 82, 1);
    box-shadow: 0 0 0 0 rgba(255, 82, 82, 1);
    border-radius: 50%;
    z-index: -1;
  }
  &:after {
    box-shadow: 0 0 0 0 rgb(255, 220, 82);
    // animation: pulse-red 1s infinite;
  }
}
.iconGreen {
  background: #61c815;

  &:after {
    box-shadow: 0 0 0 0 #61c815;
    // animation: pulse-green 1s infinite;
  }
}

@keyframes pulse-green {
  0% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 #61c815;
  }

  70% {
    transform: scale(1);
    box-shadow: 0 0 0 10px rgba(255, 82, 82, 0);
  }

  100% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(255, 82, 82, 0);
  }
}
@keyframes pulse-yellow {
  0% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgb(255, 220, 82);
  }

  70% {
    transform: scale(1);
    box-shadow: 0 0 0 10px rgba(255, 82, 82, 0);
  }

  100% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(255, 82, 82, 0);
  }
}

@keyframes pulse-red {
  0% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(255, 82, 82, 0.7);
  }

  70% {
    transform: scale(1);
    box-shadow: 0 0 0 10px rgba(255, 82, 82, 0);
  }

  100% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(255, 82, 82, 0);
  }
}
