@import 'src/assets/styles/colors';
.Button {
  display: flex;
  align-items: center;
  border: none;
  outline: none;
  background: $color-primary;
  color: white;
  border-radius: 4px;
  padding: 0px 30px;
  height: 40px;
  margin: 0px 15px;
  font-weight: 700;
  font-size: 14px;
  cursor: pointer;
  transition: all 0.3s;
  word-break: normal;
  &--small {
    padding: 5px 10px;
    font-size: 11px;
  }
  &--no-margin {
    margin: 0 !important;
  }
  &__icon {
    margin-right: 10px;
    margin-left: 0;
  }
  &:disabled {
    opacity: 0.6;
  }
  &--uppercase {
    text-transform: uppercase;
  }
  &--full-width {
    width: 100%;
  }
  &--default {
    color: #7783a1;
    background-color: $color-white;
    border-color: #dae1ed;
    border-style: solid;
    border-width: 1px;
    &:hover:not(:disabled) {
      background-color: rgba($color-medium-grey, 0.1);
    }
  }
  &--primary {
    background-color: $color-primary-dark;
    border-color: transparent;
    &:hover {
      &:hover:not(:disabled) {
        background-color: rgba($color-primary2, 0.7);
      }
    }
  }

  &--red {
    background: $color-red;
    &:hover:not(:disabled) {
      background-color: rgba($color-red, 0.7);
    }
  }
  &--blue {
    background: $color-blue;
    &:hover:not(:disabled) {
      background-color: rgba($color-blue, 0.7);
    }
  }
  &--yellow {
    background: $color-yellow;
    &:hover:not(:disabled) {
      background-color: rgba($color-yellow, 0.7);
    }
  }
  &--white {
    color: #5e5e5e;
    background-color: #fff;
    border: 1px solid #f0f0f0;
    &:hover:not(:disabled) {
      background-color: rgba($color-white, 0.7);
    }
  }

  &__loader {
    &--primary {
      border-color: $color-primary !important;
      // border-color: white !important;
      border-bottom-color: transparent !important;
    }
    &--active {
      width: 13px;
      height: 13px;
      margin-left: 5px;
      border: 2px solid #fff;
      border-bottom-color: transparent;
    }
    width: 0;
    animation-iteration-count: 3;
    height: 0;
    margin: 0;
    animation-fill-mode: both;
    display: inline-block;
    transition: all 0.3s;
    vertical-align: middle;
    background-color: #fff;
    border-radius: 100%;
    animation-duration: 1s;
    background: transparent !important;
    animation: rotate 0.75s 0s linear infinite;
  }
}

@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }
  50% {
    transform: rotate(180deg);
  }
  100% {
    transform: rotate(360deg) scale(1);
  }
}
