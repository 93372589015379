@import 'bootstrap/dist/css/bootstrap.min.css';

.g-breadcrumbs a {
  color: #42c19a;
  text-decoration: none;
}

@media print {
  .App__sidebar {
    display: none;
  }
}

a {
  text-decoration: none;
  color: #42c19a;;
}
