.AppContent {
  padding: 25px 65px;
  animation: fadeIn 0.4s ease-in both;
}


@keyframes fadeIn {
	from {
		opacity: 0;
		transform: translate3d(0, -20px, 0);
	}
	to {
		opacity: 1;
		transform: translate3d(0, 0, 0);
	}
}