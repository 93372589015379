.list {
  display: flex;
  flex-wrap: wrap;
}
.item {
  padding: 10px 20px;
  text-decoration: none;
  color: grey;
  border-radius: 5px;
  margin-right: 10px;
  margin-bottom: 10px;
  border: 1px solid #c3c3c3;
  transition: 0.12s all;
  &:hover {
    background: #f7f7f7;
    cursor: pointer;
  }
}

@media print {
  .AppContent {
    padding: 0 !important;
    position: absolute;
    top: 0;
  }

  .App__content {
    padding: 0 !important;
  }
}
