.imageUpload {
}

.imagePreview {
  overflow: hidden;
  border: 2px solid #12b886;
  border-radius: 14px;
  height: 150px !important;
  width: 150px !important;
  & img {
    height: 150px;
    width: 150px;
  }
}

.dropzoneSelectedFiles {
  width: 150px;
  height: 150px;
}

.deleteIcon {
  position: absolute;
  z-index: 3;
  right: 0px;
  transform: translateX(50%) translateY(-50%);

  &:active {
    position: absolute;
    z-index: 3;
    right: 0px;
    transform: translateX(50%) translateY(-50%);
  }
}

.preview {
  position: relative;
}