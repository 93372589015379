@import 'src/assets/styles/colors';
.FileField {
  min-height: 50px;
  &__input {
    border: none;
    padding: 0px 10px;
    font-size: 14px;
    width: 100%;
    outline: none;
    color: #7783a1;
    visibility: hidden;
    opacity: 0;
    width: 0;
    height: 0;
    &--disabled {
      background: rgba(100, 100, 100, 0.07);
      cursor: not-allowed;
    }
    &--error {
      border: 1px solid $color-red !important;
    }
    &::placeholder {
      color: #9c9c9c;
    }
    &--icon {
      padding-right: 0;
    }
  }
  &__placeholder {
    min-height: 50px;
    display: flex;
    align-items: center;
    color: #d3d3d3;
    font-weight: 600;
    &--selected {
      color: #070707;
    }
  }
  &__button {
    position: absolute;
    display: flex;
    justify-content: center;
    padding: 0 20px;
    right: 0;
    height: 100%;
    border: none;
    background: none;
    border-left: 1px solid #dae1ed;
    align-items: center;
    font-size: 15px;
    color: #7783a1;
    outline: none;
    text-align: center;
    transition: all 0.15s;
    cursor: pointer;
    &:hover {
      background: rgba(244, 244, 244, 0.8);
    }
  }
}
